export async function getPolosData () {
    const response = await fetch(`https://asperautomatico.uniergs.com.br/api`);
    const data = await response.json();
    return data;
}

export async function setPolosData(id, newData) {
    try {
        const response = await fetch(`https://asperautomatico.uniergs.com.br/api/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(newData)
        });

        return response;
        
    } catch(error){
        console.error('Erro: ', error);
    }
}