import React, { useState } from "react";
import 'font-awesome/css/font-awesome.min.css';
import { FaPhone } from "react-icons/fa6";
import { setPolosData } from "../functions/fetch_api";

/* eslint-disable no-restricted-globals */

export default function PoloCard({ item, index }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedLocation, setEditedLocation] = useState(item.location);
    const [editedPhone, setEditedPhone] = useState(item.phone);

    const editInformation = () => {
        setIsEditing(true);
    };

    const saveInformation = () => {

        let getPassword = prompt("Insira a senha de administrador");

        if(getPassword == "Uniergs4040"){
            const updatedData = {
                location: editedLocation,
                phone: editedPhone,
            };

            setPolosData(item.id, updatedData).then( response => {
                if(response.ok) {
                    setIsEditing(false);
                    location.reload();
                } else {
                    console.error(`Erro ao atualizar dados: ${response.status}`);
                }
            })
        } else {
            alert("Senha Incorreta");
            location.reload();
        }
    };

    return (
        <li className='bg-white shadow-sm px-2 m-1 py-3 rounded-md min-w-sm min-h-max group' id={item.polo_name}>
              <div>
                <div className='flex justify-between'>
                  <div className='flex align-top'>
                    <h1 className='text-blue-950 text-lg font-medium mx-1'>{item.polo_name}</h1>
                    {(item.agencia === "Servi") && <h1 className='text-blue-700 text-lg font-medium mx-1'>{item.agencia}</h1>}
                    {(item.agencia === "Uniergs") && <h1 className='text-green-700 text-lg font-medium mx-1'>{item.agencia}</h1>}
                  </div>
                  <a href={"#" + item.polo_name} className="invisible group-hover:visible" onClick={() => editInformation(index)}><i className='fa fa-gear self-end text-blue-300'></i></a>
                </div> 
                <p className='text-slate-500 font-medium text-sm mx-1' id={'address - ' + index}>
                    {isEditing ? (
                        <input 
                         name={"address - " + index}
                         type="text" 
                         value={editedLocation}
                         onChange={(event) => setEditedLocation(event.target.value)}
                        />
                    ) : (
                        item.location
                    )}
                </p>
                
                <div className='justify-between flex h-full'>
                  <p className='text-slate-700 text-sm flex font-semibold mx-1 w-100 pt-3 self-end' id={'phone - ' + index}>
                    <FaPhone className="mx-2 self-center text-green-700"/>
                    {isEditing ? (
                        <input 
                        name={"phone - " + index}
                        type="text"
                        value={editedPhone}
                        onChange={(event) => setEditedPhone(event.target.value)}/>
                    ) : (
                        item.phone
                    )}
                  </p>

                  <div hidden={!isEditing}>
                    <button className='mx-2' onClick={() => location.reload()} ><i className='fa fa-times text-red-600'></i></button>
                    <button className='' onClick={saveInformation}>
                        <i className='fa fa-check text-green-700'></i>
                    </button>
                  </div>
            </div>
        </div>
    </li>
);
}
