import React, { useEffect, useState } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import PoloCard from '../components/PoloCard';
import { getPolosData } from '../functions/fetch_api';

export default function Polos(){

    const [backendData, setBackendData] = useState([{}])
  
    useEffect(() => {
      getPolosData().then( data =>  setBackendData(data))
    }, [])

    return (
        <div className='bg-gray-100' id='Polos'>
          <h2 className='text-3xl mx-1 pt-8 px-5 font-semibold text-black'> Polos UNIASSELVI</h2>
          <p className='text-md font-normal text-slate-900 mx-1 px-5 pb-3'>lista contendo todos os polos UNIASSELVI do grupo SERVI/UNIERGS</p>
          <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-6'>
            {backendData.map((item, index) => (
              <PoloCard key={index} item={item} index={index} />
            ))}
          </ul>
        </div> 
      )
    }
    