import React, { useEffect, useRef, useState } from "react";
import serviLogo from "../assets/images/Servi.png";
import uniergsLogo from "../assets/images/Uniergs.png";
import { getPolosData } from "../functions/fetch_api";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function AspertAutomatico(){

    const secondaryPhoneNumber = "(47) 3301-6100";
    
    const overlay = require('../overlay-properties.json');
    const [file, setFile] = useState(null);
    const [backendData, setBackendData] = useState([]);
    const canvasRef = useRef(null);
    const saveButtonRef = useRef(null);
    const thumbnailImageRef = useRef(null);

    useEffect(() => {
        getPolosData().then(data => setBackendData(data));
    }, []);

    function getImageFromInput(e) {
        saveButtonRef.current.removeAttribute("hidden");
        thumbnailImageRef.current.removeAttribute("hidden");
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileURL = URL.createObjectURL(selectedFile);
            setFile(fileURL);
        }
    }

    async function loadImages() {
        const delay = 200;
        const zip = new JSZip();
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const backgroundImage = new Image(overlay.background_image.width, overlay.background_image.height);
        backgroundImage.src = file;
        backgroundImage.onload = async () => {
            const serviOverlay = new Image(overlay.logo.width, overlay.logo.height);
            serviOverlay.src = serviLogo;
            const uniergsOverlay = new Image(overlay.logo.width, overlay.logo.height);
            uniergsOverlay.src = uniergsLogo;
            console.log(serviOverlay);
            console.log("Image Loaded!! " + backgroundImage);
            context.drawImage(backgroundImage,overlay.background_image.x , overlay.background_image.y , overlay.background_image.width, overlay.background_image.height);
            serviOverlay.onload = async () => {
                uniergsOverlay.onload = async () => {
                    const folders = {
                        "Centro Sul": [],
                        "Sul": [],
                        "Serra Gaúcha": []
                    };

                    for (let index = 0; index < backendData.length; index++) {
                        const data = backendData[index];
                        await new Promise(resolve => {
                            setTimeout(() => {
                                let region;
                                switch (data.regiao) {
                                    case "Centro-Sul":
                                        region = "Centro Sul";
                                        break;
                                    case "Região Sul":
                                        region = "Sul";
                                        console.log(data.polo_name, data.regiao);
                                        break;
                                    case "Serra Gaúcha":
                                        region = "Serra Gaúcha";
                                        console.log(data.polo_name, data.regiao);
                                        break;
                                    default:
                                        break;
                                }

                                context.drawImage(data.agencia === "Uniergs" ? uniergsOverlay : serviOverlay, overlay.logo.x, overlay.logo.y, overlay.logo.width, overlay.logo.height);
                                context.fillStyle = "black";
                                context.font = "35px Neo Sans Pro Medium";
                                context.fillText(data.polo_name, 15, 1092);
                                context.font = "28px Neo Sans Pro";
                                context.fillText(data.location, 15, 1125);
                                context.font = "25px Neo Sans Pro";
                                context.fillText(`${secondaryPhoneNumber} || ${data.phone}`, 15, 1160);
                                folders[region].push(canvas.toDataURL("image/png"));
                                resolve();

                                console.log("image made! region:", region);
                            }, index);
                        });
                    }
                    let cumulativeIndexRegion = 0;

                    Object.keys(folders).forEach(region => {
                        const folder = zip.folder(region);
                        folders[region].forEach((file, index) => {    
                            folder.file(`${region}_${backendData[cumulativeIndexRegion + index].polo_name}.png`, file.substr(file.indexOf(',') + 1), { base64: true });
                            console.log("file added to folder!, index:", [cumulativeIndexRegion + index])
                        });
                        cumulativeIndexRegion += folders[region].length;
                    });
                    zip.generateAsync({ type: "blob" }).then(content => {
                        saveAs(content, `Promocional`);
                    });
                }
            }
        }            
    }

    return(
        <div className="bg-gray-100 align-middle h-screen flex-col flex justify-start w-full" id="Aspert">
            
            <div className="flex justify-between">
                
                <div className="flex flex-col">
                    
                    <h2 className='text-2xl mx-1 pt-8 px-5 font-semibold text-black md:text-3xl'> ASPERT AUTOMÁTICO </h2>
                    <p className='text-sm font-normal text-slate-950 mx-1 px-5 pb-3 md:text-lg '>Ferramenta utilizada para inserir os endereços dos polos nos promocionais disponibilizados pelo aspert</p>
                
                </div>
                
            </div>

            <div className="flex flex-col  justify-between p-6">

                <input type="file" onChange={getImageFromInput} className="file:font-bold file:py-2  file:px-4 button:bg-teal-500 
                file:text-white file:border-b-4 file:ring-0 file:border-blue-700 file:bg-blue-500  file:rounded w-fit
                file:cursor-pointer border-none focus:outline-none file:hover:border-blue-500 file:hover:bg-blue-400"/>
                
                <div className="self-center align-middle justify-center flex rounded-xl bg-slate-200 p-3 my-3 w-fit">
                <img src={file} id="thumbnailImage" ref={thumbnailImageRef} alt="thumbnail" hidden className=" w-96 rounded-xl b self-center shadow-sm
                aspect-square mx-0"/>
                </div>

                <button id="btn" ref={saveButtonRef} hidden className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 
                hover:border-green-500 rounded w-fit self-end" onClick={loadImages}>Salvar Imagens</button>

            </div>


            <canvas width={1200} height={1200} id="canvasObj" ref={canvasRef} hidden className="rounded shadow-sm aspect-square" src={file}></canvas>
       
        </div>
    )
}
