import React from 'react';
import Polos from './pages/Polos';
import AspertAutomatico from './pages/AspertAuto';

export default function App(){
  return (
    <div className='bg-gray-100 h-screen'>
      <div className='bg-gray-100'>
        <AspertAutomatico/>
        <Polos/>
      </div>
    </div>
  )
}
